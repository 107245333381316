.login-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px 50px 0px;
  min-height: calc(100vh - 550px);
}

.login-body > h1 {
  font-family: Kindest Reality;
  color: #668c3c;
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 400;
}

.login-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 20px 20px;
  width: 100%;
}

.login-btn {
  width: 140px;
  height: 45px;
  background-color: #668c3c;
  border-radius: 10px;
  border: 2px solid #668c3c;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: 0.4s;
  margin: 5px 0px 10px 25px;
  box-shadow: 3px 5px 5px #00000032;
}

.login-btn:hover {
  background-color: #fff;
  color: #668c3c;
  cursor: pointer;
}

.login-body p {
  font-size: 16px;
}

.login-body a {
  text-decoration: underline;
  color: #000;
}

.login-body a:hover {
  text-decoration: none;
  cursor: pointer;
}

.login-body a:visited {
  color: #7a3c5f;
}

.mid-section input {
  width: 100%;
  height: 50px;
  border: 1.5px solid #666;
  border-radius: 4px;
  font-size: 24px;
  padding-left: 5px;
  background-color: #ffffff00;
  z-index: 0;
  transition: 0.4s;
}

.mid-section input:focus {
  outline: none;
  border: 2px solid #444;
}

.mid-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

.email-label {
  position: relative;
  top: 38px;
  left: -125px;
  background-color: #fff;
  padding: 0px 4px 0px 4px;
  transition: 0.3s ease-out;
  z-index: -1;
}

.password-label {
  position: relative;
  top: 38px;
  left: -126px;
  background-color: #fff;
  padding: 0px 4px 0px 4px;
  transition: 0.3s ease-out;
  z-index: -1;
}

.input-selected {
  top: 10px;
  z-index: 2;
}

.li-error-line {
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: left;
}

.li-error-line p {
  color: #b50000;
  text-align: right;
  margin: 10px 0 0 0;
  visibility: hidden;
}
