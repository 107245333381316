.faq-hero-img {
  margin: 0px;
  padding: 0px;
  background-image: url("./img/faq-hero-img.jpg");
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: 50% 40%;
}

.faq-hero-img > h1 {
  font-family: Kindest Reality;
  font-size: 40px;
  font-weight: 400;
  position: relative;
  top: calc(100% - 110px);
  left: 8vw;
  margin: 0px;
  width: fit-content;
  background-color: #b55b2c;
  padding: 10px 15px 10px 15px;
  border-radius: 14px;
  color: #fff;
}

.h2green {
  color: #668c3c;
  font-family: Kindest Reality;
  font-size: 40px;
  font-weight: 400;
  padding-bottom: 0px;
}

.h2purple {
  color: #7a3c5f;
  font-family: Kindest Reality;
  font-size: 35px;
  font-weight: 400;
  padding-bottom: 0px;
}

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-item {
  margin-bottom: 15px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #668c3c;
  color: white;
  border-radius: 20px;
  max-width: 93.5%;
  margin-left: 0;
  margin-right: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.accordion-title:hover {
  background-color: #5a7d34;
}

.accordion-title,
.accordion-title2,
.accordion-content,
.accordion-content2 {
  padding: 15px;
}

.accordion-content {
  /* background-color: #527130; */
  background-color: #b55b2c;
  border-radius: 20px;
  color: white;
  max-width: 93.5%;
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0 0 0 1.5px rgb(255, 255, 255), 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: 1s linear;
}

.accordion-title2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #7a3c5f;
  color: white;
  border-radius: 20px;
  max-width: 93.5%;
  margin-left: 0;
  margin-right: auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.accordion-title2:hover {
  background-color: #6e3555;
}

.accordion-content2 {
  background-color: #568571;
  border-radius: 20px;
  color: white;
  max-width: 93.5%;
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0 0 0 1.5px rgb(255, 255, 255), 0 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
    color: white;
  }

  .accordion {
    width: 90%;
  }
}
