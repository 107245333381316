.ty-body {
  height: 100vh - 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 550px);
}

.ty-box {
  background-color: #668c3c;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 50px 0;
  padding: 25px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 420px;
}

.ty-box p {
  color: #fff;
  font-size: 20px;
  max-width: 80%;
  text-align: center;
}

.ty-box img {
  width: 250px;
}

.ty-title {
  font-family: Kindest Reality;
  font-weight: 400;
  color: #fff;
  font-size: 48px;
}
