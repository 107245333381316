.submit-resource-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .submit-resource-hero {
    margin: 0px;
    padding: 0px;
    background-image: url("./img/contact-us-hero-img.png");
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .submit-resource-hero h1 {
    font-family: "Kindest Reality", sans-serif;
    font-size: 40px;
    font-weight: normal;
    margin: 0 0 20px 8vw;
    width: fit-content;
    background-color: #5e617b;
    padding: 10px 15px;
    border-radius: 14px;
    color: #fff;
  }
  
  .submit-resource-content {
    background-color: #f5f5f5;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
  }
  
  .submit-resource-subheader {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .submit-resource-form {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-section {
    flex: 1 1 100%;
    margin-top: -20px;
    margin-bottom: -20px;
  }
  
  .checkbox-section h3 {
    margin-bottom: 5px;
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox-group .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 0;
  }
  
  .submit-button {
    margin-top: 40px !important;
    margin-bottom: 20px;
    align-self: center;
  }
  
  .error-text {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
  }
  
  @media (min-width: 750px) {
    .checkbox-section {
      flex: 1 1 calc(50% - 5px);
    }
  
    .checkbox-group {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .checkbox-group .MuiFormControlLabel-root {
      width: 50%;
      margin-right: 0;
    }
  }
  
  @media (max-width: 600px) {
    .submit-resource-hero h1 {
      font-size: 30px;
      margin-left: 20px;
    }
  
    .submit-resource-content {
      padding: 20px;
    }
  }