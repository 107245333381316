#hero-img-who-we-are {
    margin: 0px;
    padding: 0px;
    background-image: url("./img/who-we-are/IMG_5930.jpg");
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: 50% 50%;
    background-color: rgb(181, 181, 181);
}
  
#hero-img-who-we-are > h1 {
    font-family: 'Kindest Reality', Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
    font-weight: normal;
    position: relative;
    top: calc(100% - 100px);
    left: 8vw;
    margin: 0px;
    padding: 0 22px 0 22px;
    max-width: max-content;
    background-color: #568571;
    color: white;
    border-radius: 14px;
    text-align: left;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

h3 {
    font-family: 'Kindest Reality', Georgia, 'Times New Roman', Times, serif;
    font-weight: normal;
    font-size: 28px;
    margin: 70px 0 24px 0;
}

p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
}

@media screen and (min-width: 1024px) {
    #hero-img-who-we-are {
        height: 240px;
    }

    #hero-img-who-we-are > h1 {
        font-size: 48px;
        top: calc(100% - 100px);
    }

    h3 {
        font-size: 40px;
    }
    
    p {
        font-size: 21px;
    }
}

.bio-group {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0 70px 0;
    width: auto;
    gap: 25px;
}

.bio {
    display: flex;
    flex-direction: column;
    flex: 0 0 265px;
}

.bio-img {
    object-fit: cover;
    width: 250px;
    height: 250px;
    border-radius: 14px;
}

.bio-name {
    text-align: center;
    font-weight: bold;
    margin: 12px 0 0 0;
}

.bio-position {
    font-size: 16px;
    text-align: center;
    margin: 0;
}

.bio-description {
    font-size: 16px;
    line-height: 21px;
}

.description-section-about {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px auto 100px auto;
    max-width: 1000px;
    background-color: #668C3C;
    border-radius: 14px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.description-section-about-left {
    padding: 36px;
    color: white;
    flex: 1 1 400px;
}

.description-section-about-left > h2 {
    font-family: 'Kindest Reality', Georgia, 'Times New Roman', Times, serif;
    font-weight: 100;
    font-size: 32px;
    line-height: 32px;
}

.description-section-about-left > p {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    padding: 20px 0 20px 0;
    font-weight: bold;
}

#description-section-right-about {
    background-image: url("./img/who-we-are/IMG_5952.jpg");
    background-position: 50% 50%;
    background-size: cover;
    width: 300px;
    height: 50vh;
    flex: 1 1 300px;
    margin: 0;
}

.regular-btn {
    width: 120px;
    height: 40px;
    border-radius: 8px;
    border: 2.5px solid #fff;
    background-color: #fff;
    transition: 0.4s;
    box-shadow: 3px 5px 5px #00000032;
    font-size: 16px;
    font-weight: 700;
}

a {
    text-decoration: none;    
}

.lightgreen {
    color: #668C3C;
}

.regular-btn-lightgreen {
    color: #668C3C;
}

.regular-btn-lightgreen:hover {
    background-color: #668C3C;
    color: white;
    cursor: pointer;
}