.contact-list-hero-img {
  margin: 0px;
  padding: 0px;
  background-image: url("./img/contact-list-hero-img.png");
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: 50% 50%;
}

.contact-list-hero-img > h1 {
  font-family: Kindest Reality;
  font-size: 40px;
  position: relative;
  top: calc(100% - 110px);
  left: 8vw;
  margin: 0px;
  width: fit-content;
  background-color: #7A3C5F;
  padding: 10px 15px 10px 15px;
  border-radius: 14px;
  color: #fff;
  font-weight: 400;
}

.contact-list-body {
  padding: 25px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.contact-list-left {
  height: fit-content;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  position: sticky;
  top: 100px;
}

.select-box {
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 15px;
}

.select-box .options-container {
  background: #eee;
  color: #000;
  width: 100%;
  opacity: 0;
  max-height: 0;
  transition: all 0.4s;
  
  overflow: hidden;
  order: 1;
}

.select-box .option,
.selected {
  padding: 8px 8px;
  cursor: pointer;
  border-bottom: 1.5px solid #b55b2c;
  border-left: 1.5px solid #b55b2c;
  border-right: 1.5px solid #b55b2c;
}

.option {
  min-height: 30px;
}

.last-item {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selected {
  background: #0000;
  margin-bottom: 0px;
  color: #000;
  position: relative;
  order: 0;
  text-align: left;
  border: 1.5px solid #b55b2c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 40px;
  transition: 0.4s;
}

.selected.open {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.selected::after {
  content: "";
  background: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.08 22.54L26 29.46L32.92 22.54' stroke='%23121a2d' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -5px;
  top: -4px;
  transition: all 0.4s;
}

.select-box .options-container.active + .selected::after {
  transform: rotateX(180deg);
  top: -4px;
}

.select-box .options-container.active {
  max-height: 500px;
  opacity: 1;
}

.select-box label {
  cursor: pointer;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
}

.select-box .option:hover {
  background: #eee;
}

.select-box .option .radio {
  display: none;
}

.select-box2 {
  margin: 0px;
  box-sizing: border-box;
  display: flex;
  width: 70%;
  flex-direction: column;
  padding-bottom: 15px;
}

.select-box2 .options-container2 {
  background: #fff;
  color: #000;
  width: 100%;
  opacity: 0;
  max-height: 0;
  transition: all 0.4s;

  overflow: hidden;
  order: 1;
}

.select-box2 .option2,
.selected2 {
  padding: 8px 8px;
  cursor: pointer;
  border-bottom: 1.5px solid #b55b2c;
  border-left: 1.5px solid #b55b2c;
  border-right: 1.5px solid #b55b2c;
}

.option2 {
  min-height: 30px;
}

.last-item2 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selected2 {
  background: #0000;
  margin-bottom: 0px;
  color: #000;
  position: relative;
  order: 0;
  text-align: left;
  border: 1.5px solid #b55b2c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 40px;
  transition: 0.4s;
}

.selected2.open2 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.selected2::after {
  content: "";
  background: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.08 22.54L26 29.46L32.92 22.54' stroke='%23121a2d' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -5px;
  top: -4px;

  transition: all 0.4s;
}

.select-box2 .options-container2.active2 + .selected2::after {
  transform: rotateX(180deg);
  top: -4px;
}

.select-box2 .options-container2.active2 {
  max-height: 720px;
  opacity: 1;
}

.select-box2 label {
  cursor: pointer;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
}

.select-box2 .option2:hover {
  background: #eee;
}

.select-box2 .option2 .radio2 {
  display: none;
}

.contact-list-textbox {
  height: 40px;
  border: 1.5px solid #b55b2c;
  border-radius: 10px;
  font-size: 16px;
  padding-left: 8px;
  margin-bottom: 15px;
}

::placeholder {
  font-size: 16px;
}

.contact-left-container {
  flex-direction: column;
}

.contact-list-right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch;
  width: fit-content;
}

@media screen and (max-width: 1200px) {
  .contact-list-left {
    margin-left: 25px;
  }
}


@media screen and (max-width: 780px) {
  .contact-list-body {
    flex-direction: column;
    padding: 0;
  }
  .contact-list-left {
    position: relative;
    top: 0;
  }
  .contact-list-right {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contact-list-right {
    align-items: center;
  }
}

.grid {
  background: white;
  margin: 0 auto;
}

.my-masonry-grid {
  display: flex;
  margin-left: 0px; /* adjust this if you want to change the space between columns */
}
.my-masonry-grid_column {
  background-clip: padding-box;
  
  /* Styles for the column items */
  background: transparent;
  margin-bottom: 50px; /* adjust this if you want to change the space between items vertically */
}