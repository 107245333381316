#hero-img-calendar {
    margin: 0px;
    padding: 0px;
    background-image: url("./img/calendar/calendar-hero-img.jpg");
    width: 100%;
    height: 150px;
    background-size: cover;
    background-position: 50% 40%;
    background-color: rgb(181, 181, 181);
}

#hero-img-calendar > h1 {
    font-family: 'Kindest Reality', Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
    font-weight: normal;
    position: relative;
    top: calc(100% - 100px);
    left: 8vw;
    margin: 0px;
    padding: 0 22px 0 22px;
    max-width: max-content;
    background-color: #7A3C5F;
    color: white;
    border-radius: 14px;
    text-align: left;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

h3 {
    font-family: 'Kindest Reality', Georgia, 'Times New Roman', Times, serif;
    font-weight: normal;
    font-size: 28px;
    margin: 70px 0 24px 0;
    color: #7A3C5F;
}

@media screen and (min-width: 1024px) {
    #hero-img-calendar {
        height: 200px;
    }

    #hero-img-calendar > h1 {
        font-size: 48px;
        top: calc(100% - 100px);
    }

    h3 {
        font-size: 40px;
    }
    
    p {
        font-size: 21px;
    }
}

#event-group {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    align-items: flex-start;
    align-content: flex-start;
    column-gap: 24px;
}

.event-card {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #568571;
    padding: 0;
    margin-bottom: 10px;
    border-radius: 14px;
    color: white;
    min-width: 350px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.event-card-photo {
    object-fit: cover;
    width: 350px;
    height: 150px;
    margin: 0;
}

.event-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 100%;
    padding: 10px 24px;
}

.event-details {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 24px;
}

.event-details > a {
    color: white;
}

.event-description {
    font-size: 16px;
    padding: 10px 24px;
}

.newClass {
    margin-left: 5%;
    margin-top: 50px;
    width: 90%;
    height: 800px;
    border-color: white;
}
.newClass iframe {
    border: none;
  }