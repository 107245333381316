.page-container {
  background: linear-gradient(to bottom, white, #9ecb4f, #0c2b1c);
  font-family: Arial, sans-serif;
  font-size: 16px;
  padding-bottom: 60px;
}

.navbar {
  background-color: #668c3c;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 24px;
}

.page-title {
  padding-top: 20px;
  text-align: center;
  color: #0C2B1C;
  font-family: 'Kindest Reality', sans-serif;
  font-size: 44px;
}

.page-description,
.section-container,
.completion-message {
  background-color: rgba(255, 255, 255, 0.8);
  margin: 20px;
  padding: 20px;
  padding-bottom: 7px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-container > div {
  margin-bottom: 20px;
}

.page-description h2,
.section-container h2,
.section-container h3,
.completion-message h1 {
  color: #0C2B1C;
  font-size: 28px;
  margin-top: 0;
}

.section-container h2 {
  font-size: 30px;
}

.section-container a {
  color: #b55c2c;
  text-decoration: none;
}

.section-container a:hover {
  text-decoration: underline;
}

.kindest-reality {
  font-family: 'Kindest Reality', sans-serif;
}

.step-container {
  background-color: #f0f0f0;
  padding: 20px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-radius: 15px;
  outline: 2px black;
}

@media (max-width: 800px) {
  .page-container,
  .navbar,
  .page-description,
  .section-container,
  .completion-message {
    padding: 10px;
  }
}

.step-container h3 a {
  color: #0C2B1C;
  text-decoration: underline;
}

.step-container h3 a:hover {
  background-color: #badcff;
  border-radius: 5px;
  padding: 2px 5px;
}

.step-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.step-header h3 {
  flex: 1;
  margin-right: 10px;
  margin-bottom: -5px;
}

.step-checkbox {
  width: 30px;
  height: 30px;
  border: 3px solid #b55c2c;
  border-radius: 7px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  position: relative;
  top: 4px;
  pointer-events: none;
}

.step-checkbox:checked {
  background-color: white;
}

.step-checkbox:checked::after {
  content: "✓";
  font-size: 28px;
  font-weight: bold;
  color: #b55c2c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-us-link {
  color: #0C2B1C;
  text-decoration: underline;
}

.contact-us-link:hover {
  background-color: #badcff;
  border-radius: 5px;
  padding: 2px 5px;
}

.completion-message a {
  color: #0C2B1C;
  text-decoration: underline;
  font-style: italic;
}

.completion-message a:hover {
  background-color: #badcff;
  border-radius: 5px;
  padding: 2px 5px;
  font-style: italic;
}

.confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}