.container-container {
    background: linear-gradient(to bottom,
        white,
        white,
        rgb(158, 203, 79, 0.75), 
        #9ecb4f,
        #9ecb4f,
        rgb(12, 43, 28, 0.8),
        #0c2b1c
    );
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    display: content-box;
}



.sponsor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1250px;
}

.title-container {
    width: 100%; 
    overflow: hidden; 
    margin-top: -25px;
    margin-bottom: -15px;
}


.hero-image-container {
    background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    border-color: #f0ece2;
    border-width: 2px;
    padding: 20px;
    width: 100%; 
    overflow: hidden; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
}

.hero-image {
    display: block;
    max-width: 100%; 
    height: auto; 
    padding-top: 20px;
    padding-bottom: 5px;
}

.title {
    max-width: 1320px;
    margin: 0px;
    color: rgba(18, 74, 56, 1);
    font-family: 'Kindest Reality';
    text-align: center;
    font-size: 40px;
    padding-top: 25px;
    width: 100%;
}

.title-bottom {
    color: #668c3c
}

.title2 {
    margin: 0px;
    padding-top: 10px;
    color: white;
    font-family: 'Kindest Reality';
    text-align: left;
    font-size: 30;
    font-style: light;
    letter-spacing: 1.2px; 
}

.subtitle {
    line-height: 1.4;
    font-size: 18pt;
    font-style: italic;
    color: #668c3c;
}

.subtitle-white {
    line-height: 1.4;
    font-size: 15pt;
    font-style: normal;
    color: white;
}

.subtitle-white-itallic {
    line-height: 1.4;
    font-size: 15pt;
    font-style: italic;
    color: white;
}


.subtitle-bottom {
    line-height: 1.4;
    font-size: 20pt;
    margin: 0px;
    color: #668c3c;
    text-align: left;
}

.body-container {
    width: 70%;
    max-width: 1000px;;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}

.green-white-container {
    background-color: rgba(18, 74, 56, 1);
    border-color: white;
    border-width: 4px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

.orange-white-container {
    background-color: #b55c2c;
    border-color: white;
    border-width: 4px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
}

.cobalt-container {
    background-color: #5e617b;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.green-container {
    background-color: rgba(18, 74, 56, 1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gray-container {
    background-color: #F2ECEF;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5);
}

.brown-container {
    background-color: rgba(131, 98, 38, 1);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.4);
}

.body {
    width: 100%;
    text-align: left;
    margin: auto;
    font-size: larger;
    color: white;
}

.horizontal-line {
    width: 77.5vw;
    max-width: 750px;
    margin: 25px auto 25px auto; 
    background-color: #668c3c;
    height: 2px;
}

.horizontal-line-white {
    width: 77.5vw;
    max-width: 80%;
    margin: 25px auto 25px auto; 
    background-color: white;
    height: 2px;
}

.horizontal-line-green {
    width: 77.5vw;
    max-width: 80%;
    margin: 25px auto 25px auto; 
    background-color: 668c3c;
    height: 1px;
}

.myli {
    font-size: medium;
    color: white;
    list-style-type: none;
    padding-right: 40px;
    width: 80% - 50px;
}

.bottom-li:before {
    content: "";
    display: inline-block;
    width: 12px; /* size of the dot */
    height: 12px;
    background-color: #668c3c;
    border-radius: 50%; /* makes it a circle */
    margin-right: 10px; /* space between the dot and the list item */
    margin-bottom: 3.5px;
}
  

p {
    font-size: larger;
}

.event-highlights-body {
    font-size: larger;
    color: white;
}

.bingo-container .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .bingo-container .logo-image {
    border-radius: 15px;
  }
  
.gathering-card-button {
    display: inline-block;
    color: white;
    border-color:#527030;
    background-color: #668c3c;
    font-size: larger;
    font-style: bold;
    text-transform: none;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    height: 45px;
}

.gathering-card-button:hover {
    background: #527030;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button-container {
    display: flex;
    justify-content: center;
    padding-right: 50px;
}

.subtitle u {
    display: inline; 
    column-count: 1; /* in case columns are being applied */
    -webkit-column-count: 1; /* for older versions of Safari and Chrome */
    -moz-column-count: 1;    /* for Firefox */
}

.custom-link {
    color: rgba(18, 74, 56, 1);
    text-decoration: none; 
}
  
  
.custom-link:visited {
    color: rgba(18, 74, 56, 1); 
}

@media screen and (max-width: 800px) {
    .body-container {
        width: 96%;
    }
}