.single-card {
  width: 220px;
  height: fit-content;
  border: 1.5px solid #8f8f8f;
  border-radius: 10px;
  margin: 12px 12px 25px;
  overflow: hidden;
  position: relative;
}

.single-card-top {
  width: 100%;
  height: 85px;
  z-index: -1;
}

.card-profile-pic {
  border-radius: 50%;
  background-color: #ccc;
  width: 140px;
  height: 140px;
  position: absolute;
  left: calc(50% - 70px);
  top: calc(10px);
}

.card-details-div {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.card-name {
  margin-bottom: 0px;
}

.card-role {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 0px 8px;
  border-radius: 3px;
  margin: 2px 2px;
  /* Added margin */
}

.card-title {
  font-size: 16px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
}

.card-location {
  font-size: 16px;
  margin: 0;
  padding-left: 3px;
}

.card-location-div {
  display: flex;
  flex-direction: row;
  height: 12px;
}

.card-button {
  width: 180px;
  height: 40px;
  margin: 10px 0 10px 0;
  background-color: #fff;
  border: 1.5px solid #b55b2c;
  border-radius: 10px;
  color: #b55b2c;
  font-weight: 600;
  font-size: 20px;
  transition: 0.4s;
}

.card-button:hover {
  color: #fff;
  background-color: #b55b2c;
}

.card-profile-pic>img {
  border-radius: 50%;
  width: 140px;
  height: 140px;
}

.location-pin-div {
  display: flex;
  flex-direction: row;
}

.location-pin-div p {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
