.ty-body {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 550px);
  }
  
  .ty-box {
    background-color: #668c3c;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 20px 0;
    padding: 10px;
    padding-top: 0px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 80%;
    max-width: 800px;
  }
  
  .ty-p {
    color: #fff;
    font-size: 20px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .bottom-link {
    font-size: 18px;
    font-style: italic;
    text-decoration: underline;
    color: #fff;
  }
  
  .ty-img {
    max-width: 250px;
    padding-bottom: 25px;
  }
  
  .ty-title {
    font-family: Kindest Reality;
    font-weight: 400;
    color: #fff;
    font-size: 48px;
    margin-top: 0px;
    margin-bottom: 25px;
    text-align: center;
    line-height: 50px;
  }
  
  .member-button {
    width: 100%;
    max-width: 100%;
    padding-top: 200px;
    margin-top: 10px;
  }

  .custom-button {
    width: 250px;
    height: 50px;
    background-color: #ffffff;
    color: #669c3c;
    font-size: 19px;
    border: none;
    border-radius: 7px; 
    cursor: pointer;
    text-align: center;
    display: flex; 
    align-items: center;
    justify-content: center; 
    margin-top: -15px;
    margin-bottom: 20px;
  }
  
  .custom-button:hover {
    background-color: #f3f3f3;
  }
  