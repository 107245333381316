.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dialog {
    background-color: #f0ece2;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .dialog-title {
    font-family: 'Kindest Reality', sans-serif;
    font-size: 30px;
    color: #668c3c;
    margin: 0;
    margin-top: -10px;
  }
  
  .dialog-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -12px;
    color: #888888;
    transition: color 0.3s ease;
  }
  
  .dialog-close:hover {
    color: #ff9999;
  }
  
  .dialog-content {
    margin-bottom: 20px;
    color: #555;
  }
  
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .dialog-button {
    background-color: #668c3c;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .dialog-button:hover {
    background-color: #557a2e;
  }